<template>
  <div class="member view">
    <app-header :title="__('view.community.member.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refresh"
          @click:clear="refresh"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button control-bar__button--with-text"
             :title="__('view.community.member.copy_section_leaders_to_clipboard')"
             @click="copySectionLeaders" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/copy.svg"
               alt="copy leaders"/>
          <div class="control-bar__button-text">{{__('view.community.member.copy_section_leaders')}}</div>
        </div>
        <div class="control-bar__button control-bar__button--with-text"
             :title="__('view.community.member.copy_leaders_to_clipboard')"
             @click="copyLeaders" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/copy.svg"
               alt="copy leaders"/>
          <div class="control-bar__button-text">{{__('view.community.member.copy_leaders')}}</div>
        </div>
        <div class="control-bar__button control-bar__button--with-text"
             :title="__('view.community.member.copy_members_to_clipboard')"
             @click="copyMembers" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/copy.svg"
               alt="copy leaders"/>
          <div class="control-bar__button-text">{{__('view.community.member.copy_members')}}</div>
        </div>
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
      </div>
    </div>
    <div class="member-list">
      <div class="member-list__member"
           v-for="user in filteredMembers"
      >
        <div class="member-list__member-avatar" v-if="user.avatar" :src="user.avatar"
             :style="{'background-image': `url(${user.avatar})`}"></div>
        <div class="member-list__member-avatar" v-else style="opacity: .5;"
             :style="{'background-image': 'url(https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png)'}"></div>
        <div class="member-list__member-info">
          <div class="member-list__member-username">{{user.userName}}</div>
          <div class="member-list__member-groups">
            <ul class="futuristic">
              <li v-for="player in user.players">{{ player.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <input id="list_section_leaders" :value="sectionLeaders().join(',')" readonly style="opacity: 0"/>
    <input id="list_leaders" :value="leaders().join(',')" readonly style="opacity: 0"/>
    <input id="list_members" :value="members().join(',')" readonly style="opacity: 0"/>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, pageTitleMixin],
    components: {
      AppHeader
    },
    data: () => ({
      loading: false,
      users: [],
      searchTerm: '',
      roles: []
    }),
    computed: {
      filteredMembers() {
        if (this.searchTerm.length > 0) {
          const lTerm = this.searchTerm.toLowerCase();
          return this.users.filter(user => {
            if (user.username.toLowerCase().indexOf(lTerm) > -1) {
              return true;
            }
          });
        }
        return this.users;
      }
    },
    methods: {
      copySectionLeaders() {
        let copyText = document.getElementById("list_section_leaders");
        copyText.select();
        document.execCommand("copy");
        this.showSuccessNotification(this.__('view.community.member.section_leaders_copied'));
      },
      copyLeaders() {
        let copyText = document.getElementById("list_leaders");
        copyText.select();
        document.execCommand("copy");
        this.showSuccessNotification(this.__('view.community.member.leaders_copied'));
      },
      copyMembers() {
        let copyText = document.getElementById("list_members");
        copyText.select();
        document.execCommand("copy");
        this.showSuccessNotification(this.__('view.community.member.members_copied'));
      },
      members() {
        if (!this.users.length > 0) {
          return [];
        }
        const playerNames = [];
        this.users.forEach(user => {
          user.players.forEach(player => {
            if (player.name !== null) {
              playerNames.push(player.name);
            }
          })
        });
        return playerNames;
      },
      leaders() {
        if (!this.users.length > 0) {
          return [];
        }
        const playerNames = [];
        this.users.forEach(user => {
          if (user.roleNames.includes('Verantwortlicher')) {
            user.players.forEach(player => {
              if (player.name !== null) {
                playerNames.push(player.name);
              }
            });
          }
        });
        return playerNames;
      },
      sectionLeaders() {
        if (!this.users.length > 0) {
          return [];
        }
        const playerNames = [];
        this.users.forEach(user => {
          let isPrime = false;
          let isVice = false;
          this.roles.forEach(role => {
            if (role.primeId === user.id)
              isPrime = true;
            if (role.viceId === user.id)
              isVice = true;
          });
          if (user.roleNames.includes('Organisationsleitung') || isPrime || isVice) {
            playerNames.push(user.players.filter(player => player.name !== null).map(player => player.name));
          }
        });
        return playerNames;
      },
      async refresh() {
        await this.refreshRole();
        await this.refreshUsers();
      },
      async refreshUsers() {
        this.loading = true;
        await apiSecured.get('/community/user', {
          params: {
            page: 1,
            pageSize: 10000,
            roleName: 'Member',
            orderBy: 'userName',
          }
        }).then((res) => {
          this.users = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.users_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async refreshRole() {
        this.loading = true;
        await apiSecured.get('/identity/role', {
          params: {
            page: 1,
            pageSize: 10000,
            searchQuery: 'Bereich',
            orderBy: 'name',
          }
        }).then((res) => {
          this.roles = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.roles_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
    },
    created() {
      this.setPageTitle(this.__('common.members'));
      this.refresh();
    }
  }
</script>